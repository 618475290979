* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   color: #363d63;
}

.checkbox {
   display: none;
}

.min-100vh {
   min-height: 100vh;
}
.cardBasket {
   min-height: 400px;
}

.checkbox:checked ~ .basketItems {
   background-color: rgb(144, 252, 216);
}

.basketItems {
   box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.26);
   -webkit-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.26);
   -moz-box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.26);
   min-height: 2rem;
   border-radius: 5px;
}

.arrowButton {
   width: 30px;
   height: 30px;
   background-color: #363d63 !important;
}
.arrowButton:hover {
   background-color: #676c8d !important;
}
.arrowButton:active {
   transform: translateY(1px);
}

.arrowIcon {
   color: white !important;
}

.paddingColor {
   background-color: #e9f2ea;
}
.inputColor {
   background-color: white !important;
   border: none;
}
.numberOfBasket {
   background-color: #363d63;
   color: white;
   font-size: 20px;
   padding: 5px;
   height: 30px;
   width: 30px;
   border-radius: 5px;
}
.iconButton {
   height: 20px;
   width: 20px;
   background-color: transparent !important;
}
